import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bc1a6a2a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "submitForm"
};
const _hoisted_2 = {
  class: "el_tree"
};
const _hoisted_3 = {
  style: {
    "height": "180px",
    "overflow-y": "auto"
  }
};
const _hoisted_4 = {
  class: "list_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_OrganizeSelect = _resolveComponent("OrganizeSelect");
  const _component_icon = _resolveComponent("icon");
  const _component_List = _resolveComponent("List");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_form = _resolveComponent("el-form");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => _ctx.show = $event),
    title: _ctx.title,
    onConfirmOk: _ctx.onConfirm,
    width: "508px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
      ref: "ruleForm",
      rules: _ctx.rules,
      "validate-on-rule-change": false,
      model: _ctx.formData,
      "label-width": "98px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "角色名称",
        prop: "name"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.formData.name = $event),
          placeholder: "请输入角色名称"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "角色代码",
        prop: "code"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.code,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.formData.code = $event),
          placeholder: "角色代码"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "角色描述",
        prop: "remark"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.remark,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.formData.remark = $event),
          placeholder: "请输入角色描述"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "角色权限",
        prop: "resourceIdList"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tree, {
          data: _ctx.TreeResource,
          "show-checkbox": "",
          "default-expanded-keys": [1],
          "default-checked-keys": [11],
          "node-key": "id",
          ref: "treeRef",
          onCheck: _ctx.handleCheck,
          props: _ctx.props
        }, null, 8, ["data", "onCheck", "props"])])]),
        _: 1
      }), false ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "选择单位",
        prop: "dataScopeDeptIds"
      }, {
        default: _withCtx(() => [_createVNode(_component_OrganizeSelect, {
          type: 1,
          treeType: "",
          isSpecify: 'org',
          isClick: false,
          isDoubleClick: "",
          onHandleDoubleClick: _ctx.handleDoubleClick
        }, null, 8, ["onHandleDoubleClick"]), _createVNode(_component_el_card, null, {
          header: _withCtx(() => [_createTextVNode("绑定单位")]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_List, {
            list: _ctx.formData.dataScopeDeptIds,
            checkbox: false
          }, {
            default: _withCtx(({
              item
            }) => [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(item.name) + " ", 1), _createVNode(_component_icon, {
              class: "delete_icon",
              name: "Delete",
              onClick: $event => _ctx.onDeleteSchool(item)
            }, null, 8, ["onClick"])])]),
            _: 1
          }, 8, ["list"])])]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["rules", "model"])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}