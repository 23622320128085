import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent, ref } from "vue";
import API from "@/api/authority/index";
import { useDebounce } from '@/hooks/core/useDebounce';
import { useStore } from "vuex";
import { useMessage } from '@/hooks/web/useMessage';
export default defineComponent({
  components: {
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue"))
  },
  props: {
    activeOrg: {
      type: Object,
      default: {
        orgId: null,
        orgName: ''
      }
    },
    Info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeRoleId: {
      type: Number
    }
  },
  emits: ["handleSuccess", "update:visible"],
  setup(props, {
    emit
  }) {
    const {
      state
    } = useStore();
    const message = useMessage();
    const refData = reactive({
      searchValue: "",
      activeValue: null,
      treeRef: ref(null),
      onoff: false,
      props: {
        label: 'name',
        children: 'children'
      },
      list: [],
      deptList: [],
      value: "",
      ruleForm: null,
      formData: null,
      title: '新增角色',
      rules: {
        orgId: [{
          required: true,
          message: '请选择所属组织'
        }],
        roleName: [{
          required: true,
          message: '请输入角色名称'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '角色名称不能带有特殊符号'
        }],
        roleDesc: [{
          required: true,
          message: "请输入角色描述",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '角色描述不能带有特殊符号'
        }],
        resourceIdList: [{
          required: true,
          message: '请至少一个选择权限',
          type: 'array'
        }]
      },
      confirm() {
        getCheckedNodes();
        refData.ruleForm.validate(async valid => {
          if (valid) {
            const url = props.activeRoleId ? 'editRole' : 'addRole';
            const {
              code,
              msg,
              data
            } = await API[url](refData.formData);
            code == 0 && (emit('handleSuccess'), emit("update:visible", false));
            message[code == 0 ? 'success' : 'warning'](code == 0 ? `${props.activeRoleId ? '修改' : '添加'}成功` : msg);
            if (code == 0) {
              const roleId = props.activeRoleId ? props.activeRoleId : data;
              assignRoleMenu(roleId, state.userInfo.menuIdList);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      async getData(id) {
        const {
          data,
          code,
          msg
        } = await API.getByIdRole({
          id
        });
        if (code == 0) {
          refData.searchValue = data.name;
          refData.formData = data;
          if (refData.formData.dataScopeDeptIds == null) {
            refData.formData.dataScopeDeptIds = [];
          }
          setCheckedNodes();
        }
      },
      init() {
        refData.searchValue = '';
        refData.treeRef && refData.treeRef.setCheckedNodes([]);
        refData.formData = {
          remark: '',
          sort: 1,
          dataScope: 4,
          dataScopeDeptIds: [],
          code: '',
          id: null,
          name: '',
          resourceIdList: []
        };
      }
    });
    const TreeResource = computed(() => {
      const arr = [];
      state.TreeResource.map((item, index) => {
        const {
          children,
          ...params
        } = item;
        const json = params;
        if (item.children != null && item.hasOwnProperty('children')) {
          json.children = [];
          item.children.map(val => {
            state.userInfo.resourceIdList.includes(val.id) && json.children.push(val);
          });
        }
        ;
        state.userInfo.resourceIdList.includes(item.id) && arr.push({
          ...json,
          disabled: !index ? true : false
        });
      });
      return [{
        id: 1,
        name: state.userInfo.loginName,
        children: arr
      }];
    });
    const assignRoleMenu = async (roleId, menuIds) => {
      const {
        code,
        msg
      } = await API.assignRoleMenu({
        roleId,
        menuIds
      });
      if (code !== 0) {
        message[code == 0 ? 'success' : 'warning'](msg);
      }
    };
    function onDeleteSchool(item) {
      refData.formData.dataScopeDeptIds = refData.formData.dataScopeDeptIds.filter(school => school.id !== item.id);
    }
    ;
    const userInfo = computed(() => state.userInfo);
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
        emit("handleSuccess");
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        if (props.activeRoleId !== null) {
          refData.title = '修改角色';
          refData.getData(props.activeRoleId);
        } else {
          nextTick(() => {
            refData.init();
            //refData.formData.deptId = props.activeOrg.orgId;
            refData.searchValue = props.activeOrg.orgName;
            refData.activeValue = props.activeOrg.activeValue;
          });
          refData.title = '添加角色';
        }
        nextTick(() => {
          refData.ruleForm.resetFields();
          refData.treeRef.setChecked(TreeResource.value[0].children[0], true, false);
        });
      }
    }, {
      immediate: true
    });
    //选择权限
    const getCheckedNodes = () => {
      refData.formData.resourceIdList = [];
      const params = refData.treeRef.getCheckedNodes();
      params.map(item => {
        !refData.formData.resourceIdList.includes(item.parentId) && item.parentId && item.parentId != -1 && refData.formData.resourceIdList.push(item.parentId);
        refData.formData.resourceIdList.push(item.id);
      });
    };
    //绑定单位
    function handleDoubleClick(item) {
      const len = refData.formData.dataScopeDeptIds.some(school => school.id == item.id);
      !len && refData.formData.dataScopeDeptIds.push(item);
    }
    //设置默认选中tree
    const setCheckedNodes = () => {
      const list = [];
      TreeResource.value[0].children.map((item, index) => {
        if (item.children != null && item.hasOwnProperty('children')) {
          item.children.map(val => {
            refData.formData.resourceIdList.includes(val.id) && refData.treeRef.setChecked(val, true, false);
            if (val.children != null && val.hasOwnProperty('children')) {
              val.children.map(key => {
                refData.formData.resourceIdList.includes(key.id) && refData.treeRef.setChecked(key, true, false);
              });
            }
          });
        } else {
          refData.formData.resourceIdList.includes(item.id) && refData.treeRef.setChecked(item, true, false);
        }
      });
    };
    const handleCheck = () => {
      nextTick(() => {
        refData.ruleForm.clearValidate();
      });
    };
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      show,
      getCheckedNodes,
      handleDoubleClick,
      TreeResource,
      onDeleteSchool,
      userInfo,
      handleCheck,
      onConfirm
    };
  }
});