import "core-js/modules/es.array.push.js";
import { computed, defineComponent, reactive, toRefs, nextTick, unref, watch, inject, ref } from 'vue';
import { ElScrollbar, ElPagination, ElCheckbox, ElTag } from 'element-plus';
export default defineComponent({
  name: 'List',
  components: {
    ElScrollbar,
    ElPagination,
    ElCheckbox,
    ElTag
  },
  emits: ['update:modelValue', 'rowClick', 'checkedChange', 'checkedAll'],
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      }
    },
    list: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    idKey: {
      type: String,
      default: 'id'
    },
    pageSize: {
      type: Number,
      default: 50
    },
    checkbox: {
      type: Boolean,
      default: false
    },
    checkDisabled: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Boolean,
      default: false
    },
    liHover: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      currentPage: 1
    });
    const refRoot = inject('rootProvide');
    const pageData = ref([]);
    //员工总数
    const filterClassIds = computed(() => refRoot ? refRoot.filterClassIds : []);
    // //勾选数量
    const studentCheckedIds = computed(() => refRoot ? refRoot.studentList.filter(student => refRoot.studentCheckedIds.includes(student.objectid)) : []);
    //当前类型
    const filterType = computed(() => refRoot ? refRoot.filterType : 0);
    //员工总数
    const studentTotal = computed(() => {
      if (refRoot && props.checkbox) {
        if (refRoot.filterType == 0) {
          return refRoot.studentTotal ? refRoot.studentTotal : 0;
        } else if (refRoot.filterType == 2) {
          return refRoot.studentOffline ? refRoot.studentOffline : 0;
        } else {
          return refRoot.studentOnline ? refRoot.studentOnline : 0;
        }
      } else {
        return props.list.length;
      }
    });
    //当前请求分页数
    const pageNo = computed(() => refRoot ? refRoot.pageNo : 1);
    const pageSize = computed(() => props.pagination ? props.pageSize : props.list.length);
    const allChecked = computed(
    // () => new Set(props.modelValue).size === props.list.length
    () => studentCheckedIds.value.length === refRoot.studentList.length);
    watch(() => filterType.value, data => {
      refRoot.pageNo = 1;
      refData.currentPage = 1;
    });
    watch(() => filterClassIds.value, data => {
      refData.currentPage = 1;
    }, {
      immediate: true
    });
    watch(() => props.list, data => {
      getData();
    }, {
      immediate: true
    });
    watch(() => studentTotal.value, (val, old) => {
      getData();
    }, {
      immediate: true
    });
    watch(() => refData.currentPage, (val, old) => {
      if (val <= 0 && refRoot) {
        refData.currentPage = 1;
        // setTimeout(() => {
        refRoot.pageNo = 1;
        // }, 100);
      }
      if (val > old && (val - 1) % 6 == 0 || val < old && val % 6 == 0) {
        // setTimeout(() => {
        refRoot && (refRoot.pageNo = Math.ceil(val / 6));
        // }, 100);
      }
      getData();
    });
    function getData() {
      nextTick(() => {
        if (unref(pageNo) > 1 && props.checkbox) {
          const num = 1200 / 200 * (refRoot.pageNo - 1);
          pageData.value = props.list.slice((refData.currentPage - num - 1) * 200, (refData.currentPage - num) * 200);
        } else {
          pageData.value = props.list.slice((refData.currentPage - 1) * 200, refData.currentPage * 200);
        }
      });
    }
    //分页
    function handlePage(type) {
      if (type == 'prev') {
        refData.currentPage > 1 ? refData.currentPage -= 1 : refData.currentPage = 1;
      } else {
        refData.currentPage != Math.ceil(studentTotal.value / 200) ? refData.currentPage += 1 : refData.currentPage = refData.currentPage;
      }
    }
    function getId(item) {
      return item[props.idKey];
    }
    function onChangeAll(checked) {
      if (checked) {
        changeModelValue(props.list.filter(item => !isDisabled(item)).map(item => getId(item)));
      } else {
        changeModelValue([]);
      }
      emit('checkedAll', checked);
    }
    function onChangeCheck(item, checked) {
      let ids = [...props.modelValue];
      if (checked) {
        ids.push(getId(item));
      } else {
        ids = ids.filter(id => id !== getId(item));
      }
      changeModelValue(ids);
      const checkeds = props.list.filter(item => ids.includes(getId(item)));
      checkedChange(checked, checkeds, item);
    }
    function changeModelValue(modalValue) {
      emit('update:modelValue', modalValue);
    }
    function checkedChange(checked, checkeds, item) {
      emit('checkedChange', checked, checkeds, item);
    }
    function onRowClick(item, index) {
      emit('rowClick', item, index);
    }
    // 是否禁用checkbox
    function isDisabled(item) {
      return props.checkDisabled.includes(getId(item));
    }
    // checkbox是否勾选
    function isChecked(item) {
      return props.modelValue.includes(getId(item));
    }
    return {
      ...toRefs(refData),
      getId,
      allChecked,
      pageData,
      onChangeAll,
      onChangeCheck,
      onRowClick,
      isDisabled,
      isChecked,
      studentTotal,
      handlePage,
      filterType
    };
  }
});